import React, { useState, useContext, useEffect } from 'react';
import {
    Dialog,
    Slide,
    AppBar,
    IconButton,
    Typography,
    Toolbar,
    Button,
    List,
    ListItem,
    ListItemText,
    Divider,
    Drawer,
} from "@mui/material";
import MuiDialogContent from "@mui/material/DialogContent";
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import MenuIcon from '@mui/icons-material/Menu';
import OwnerRoomDialogVariants from "./owner.room.dialog.variants"
import CloseIcon from "@mui/icons-material/Close";
import { ReservationContext } from '../../../contexts/reservation.context';
import { makeStyles } from '@mui/styles';
import OutMenu from "../menu/owner.menu.outmenu";
import RoomCart from "./owner.room.dialog.cart.mobile"

const useStyles = makeStyles((theme) => ({
    dialogHeader: {
       // backgroundColor: theme.palette.primary.main + ' !important',
    },
    sectionContainer: {
        marginTop: theme.spacing(6) + ' !important',
        paddingTop:'20px',
        backgroundColor:'#303030 !important'
    },
    dishCard: {
        display: 'flex',
        flexWrap: 'wrap',
        marginBottom: theme.spacing(0.5) + ' !important',
        width: '100%',
        borderRadius: theme.spacing(1) + ' !important',
        //backgroundColor: '#009246 !important',
        color: '#fff',
        height: 'auto',
        padding: theme.spacing(0.1) + ' !important', // Puoi regolare questo valore per adattarlo alle tue esigenze
    },
    sectionTitle: {
        marginBottom: theme.spacing(0.5) + ' !important',
        fontWeight: 'bold',
    },
    categoryButton: {
        margin: theme.spacing(0.5) + ' !important',
        padding: theme.spacing(0.5) + ' !important',
        borderRadius: theme.spacing(1) + ' !important',
        color: theme.palette.primary.contrastText + ' !important',
        backgroundColor: theme.palette.primary.main + ' !important',
        '&:hover': {
            backgroundColor: theme.palette.primary.dark + ' !important',
        },
    },
    categoryTab: {
        maxWidth: 240,
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
    },
    list: {
        width: 250,
    },

    colorTextCategories: {
        color: theme.palette.primary.main + ' !important',
        fontVariant: 'small-caps'
    },

    colorSelectedCategory: {
        color: theme.palette.secondary.main + ' !important',
    },

    numberOrder: {
        color: '#fff',
        backgroundColor: 'red',
        borderRadius: '50%', // Utilizzare 50% per creare un cerchio perfetto
        padding: '2px 10px', // Puoi regolare il padding in base alle tue esigenze
        // display: 'inline-block', // Per assicurarti che il cerchio si adatti correttamente al contenuto
        textAlign: 'center', // Centra il contenuto all'interno del cerchio
        //minWidth: '20px', // Larghezza minima per assicurarti che il cerchio sia visibile
        marginLeft: '10px'
    },
    appBarMenu: {
        backgroundColor:'#000 !important'
    }
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function DialogMenuMobile({ openDialog, setOpenDialog, handleClickOrder, tableId, dataMenuObj, ownerId, table }) {
    const classes = useStyles();
    const [dish, setDish] = useState([])
    const [categorySelected, setCategorySelected] = useState("");
    const [selectedCategory, setSelectedCategory] = useState(0);
    const [showOutMenu, setShowOutMenu] = useState(false);
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [dialogVariants, setDialogVariants] = useState({
        isOpen: false,
        data: {}
    });
    const [openCartDialog, setOpenCartDialog] = useState(false);
    const [amountTotalCart, setAmountTotalCart] = useState({});
    const { addToCart, cart, setCart } =
        useContext(ReservationContext);//TODO SERVE QUESTO? C'è UN MODO DIVERSO??


    useEffect(() => {
        if (dataMenuObj && dataMenuObj.categories && dataMenuObj.categories.length > 0) {
            const firstCategoryId = dataMenuObj.categories[0].id;
            const firstCategoryName = dataMenuObj.categories[0].name;

            getNameDish(firstCategoryId, firstCategoryName);
            setSelectedCategory(0);
        }// eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataMenuObj]);



    const getNameDish = (categoryId, nameCategoria) => {
        setCategorySelected(nameCategoria);
        // Filtra e ordina i piatti per nome in modo alfabetico
        let filterDish = dataMenuObj.dataMenu
            .filter(el => el.categoryId === categoryId)
            .sort((a, b) => a.name.localeCompare(b.name));

        setDish(filterDish);
    }

    const handleChangeCategory = (event, newValue) => {
        setSelectedCategory(newValue);
    };


    const handleClickNameDish = (dish) => {
        if (dish.variants === null) dish.variants = []; // Perché in alcuni variants abbiamo null invece di un array vuoto;

        for (let x = 0; x < dish.variants.length; x++) {
            dish.variants[x].options = dish.variants[x].options.sort((a, b) => {
                if (a.label.toLowerCase().trim() < b.label.toLowerCase().trim()) {
                    return -1;
                }
                if (a.label.toLowerCase().trim() > b.label.toLowerCase().trim()) {
                    return 1;
                }
                return 0;
            });

        }

        if (dish.variants.length !== 0) {
            setDialogVariants({ isOpen: !dialogVariants.isOpen, data: dish });
        }
        else addToCart({ ...dish, amount: 1, note: "" })
    }

    const handleToggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    const handleSelectCategory = (categoryId, categoryName, index) => {
        getNameDish(categoryId, categoryName);
        setSelectedCategory(index);
        setIsMenuOpen(false);  // Chiude il menu dopo aver selezionato una categoria
    };

    const getAmountTotal = (totalCart) => {
        const amountMap = {};

        totalCart.forEach((dish) => {
            const { id, amount } = dish;
            amountMap[id] = (amountMap[id] || 0) + amount;
        });

        setAmountTotalCart(amountMap);
    }


    return (
        <MuiDialogContent>
            <Dialog onClose={handleClickOrder} TransitionComponent={Transition} fullScreen open={openDialog}>
                <OwnerRoomDialogVariants setDialogVariants={setDialogVariants} dialogVariants={dialogVariants} addToCart={addToCart} />
                <AppBar className={classes.dialogHeader} position="sticky">
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={handleClickOrder}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>
                        <Typography variant="h6">{(table?.tableId || table?.id) ? 'Tavolo ' + (table?.tableId || table?.id) : table?.deliveryId ? (table?.tipo + ' ' + table?.nome + ' ' + table?.cognome) : 'Nuova consegna / ritiro'}</Typography>
                    </Toolbar>
                </AppBar>


                <AppBar
                    position="fixed"
                    style={{ top: "52px"}}
                    className={classes.appBarMenu}
                >
                    <Toolbar>
                        {/* Icona del menu qui */}
                        <IconButton edge="start" color="inherit" onClick={handleToggleMenu}>
                            <MenuIcon />
                        </IconButton>
                        {/* Altri componenti della AppBar, come i tab */}
                        <Tabs
                            value={selectedCategory}
                            onChange={handleChangeCategory}
                            indicatorColor='secondary'
                            textColor='secondary'
                            variant='scrollable'
                            scrollButtons='auto'
                        >
                            {dataMenuObj.categories.map(({ id, name }) => (
                                <Tab
                                    label={
                                        <Typography
                                            component='span'
                                            className={classes.categoryTab}
                                            variant='inherit'
                                        >
                                            {name}
                                        </Typography>
                                    }
                                    key={id}
                                    onClick={() => getNameDish(id, name)}
                                />
                            ))}
                        </Tabs>
                    </Toolbar>
                </AppBar>

                <Drawer style={{zIndex:999999}} anchor={'left'} open={isMenuOpen} onClose={() => setIsMenuOpen(!isMenuOpen)}>
                    <div
                        className={classes.list}
                        role='presentation'
                    >

                        <List>
                            <ListItem>
                                <ListItemText className={classes.colorTextCategories} primary={'Scegli la categoria'} />
                            </ListItem>
                            <Divider />
                            {dataMenuObj.categories.map(({ id, name }, index) => (
                                <ListItem dense className={selectedCategory === index ? classes.colorSelectedCategory : ''} button key={id} onClick={() => handleSelectCategory(id, name, index)}>
                                    <ListItemText primary={name} />
                                </ListItem>
                            ))}
                        </List>
                    </div>
                </Drawer>

                <div className={classes.sectionContainer}>
                    <Typography variant="h6" align="center" color='secondary' className={classes.sectionTitle}>{categorySelected}</Typography>
                    <div className={classes.dishCard}>
                        {dish.map((dish, index) => (
                            <Button key={index} className={classes.categoryButton} onClick={() => handleClickNameDish(dish)}>
                                {dish.name}
                                {Object.keys(amountTotalCart).map((key, index) => dish.id === key && (<span key={index} className={classes.numberOrder}>{amountTotalCart[key]}</span>))}
                            </Button>
                        ))}
                    </div>
                </div>

                <OutMenu dishes={cart} setDishes={setCart} setShowOutMenu={setShowOutMenu} showOutMenu={showOutMenu} />

                <RoomCart getAmountTotal={getAmountTotal} setOpenCartDialog={setOpenCartDialog} cart={cart} handleClickOrder={handleClickOrder} openCartDialog={openCartDialog} tableId={(table?.tableId || table?.id)} deliveryId={table?.deliveryId} ownerId={ownerId}/>


            </Dialog>
        </MuiDialogContent>
    );
}

export default DialogMenuMobile;
