import React, { useContext, useEffect, useState } from "react";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Divider, Typography, Button, Badge, Grid } from '@mui/material'
import { makeStyles } from '@mui/styles';
import axios from 'axios';
import { ReservationContext } from "../../../contexts/reservation.context";
import { UserContext } from "../../../contexts/user.context";
import socketIOClient from "socket.io-client";
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';

const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(6) + ' !important',
        paddingLeft: theme.spacing(0) + ' !important',
        paddingRight: theme.spacing(0) + ' !important'
    },
    summary: {
        marginBottom: theme.spacing(4),
    },
    orderItem: {
        marginBottom: theme.spacing(2),
    },
    fontItalic: {
        fontStyle: 'italic'
    },
    fontWeight: {
        fontWeight: 'bold'
    },
    centeredIcon: {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
        marginTop: '200px',
        gap: '40px'
    }
}));

function OrderSummary({
    formData,
    totalPrice,
    goBack,
    cart,
    getDishPrice,
    closeDialogForm,
    isOwner,
    ownerId
}) {
    const classes = useStyles();
    const { eraseOrder } = useContext(ReservationContext);
    const { user } = useContext(UserContext);
    const [socket, setSocket] = useState(null);
    const getSocketId = () => (socket ? socket.id : null);
    const [successOrder, setSuccessOrder] = useState(false);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (user && user.id) {
            const s = socketIOClient(process.env.REACT_APP_SERVER_URL, {
                secure: true,
                transports: ["websocket"],
                query: { ownerId: user.id },
            });
            setSocket(s);
        }// eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ownerId]);

    const getDashVariants = (variants) => {
        const variantsArr = [];
        for (let x of variants) {
            if (Array.isArray(x.selected)) {
                for (let y of x.selected) {
                    variantsArr.push(y.label);
                }
            } else variantsArr.push(x.selected.label);
        }
        return variantsArr.join(', ');
    }

    const handleOrder = () => {
        // Disabilita il pulsante
        setLoading(true);

        axios
            .post("/api/reservation/user/orderDelivery", {
                ...formData,
                cart: cart.filter(({ amount }) => amount > 0),
                ownerId: ownerId,
                socketId: getSocketId(),
                isOwner
            })
            .then(({ data }) => {
                if (data.success) {
                    setSuccessOrder(true);
                    sessionStorage.removeItem("reservationDishes");
                    sessionStorage.removeItem("cart");
                    eraseOrder();
                }
            })
            .catch(error => {
                // Gestire eventuali errori qui, se necessario
            })
            .finally(() => {
                // Riabilita il pulsante dopo che la richiesta è stata completata
                setLoading(false);
            });
    };

    const getSumTotal = () => {
        let costoConsegna = 0;

        if (formData.deliveryOption.toLowerCase() === 'consegna')
            costoConsegna = parseFloat(formData.costoConsegna);

        return (totalPrice + costoConsegna).toFixed(2);

    }

    return (
        <>
            {successOrder ? (
                <>
                    <Typography variant="h4" style={{ marginTop: '45px', marginBottom: '35px' }}>
                        {isOwner ? (
                            <div className={classes.centeredIcon}>
                                <AssignmentTurnedInIcon color='primary' style={{ fontSize: '90px' }} />
                                <Typography
                                    variant='subtitle1'
                                    color='textSecondary'
                                    style={{ textAlign: 'center', letterSpacing: '1px' }}>
                                    Ordine effettuato con successo!
                                </Typography>
                            </div>
                        ) : (
                            <div className={classes.centeredIcon}>
                                <ForwardToInboxIcon color='primary' style={{ fontSize: '90px' }} />
                                <Typography
                                    variant='subtitle1'
                                    color='textSecondary'
                                    style={{ textAlign: 'center', letterSpacing: '1px' }}>
                                    Abbiamo ricevuto il tuo ordine!
                                    <br /> Ti invieremo un'email con i dettagli appena sarà confermato
                                </Typography>
                            </div>
                        )}
                    </Typography>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <Button style={{ minWidth: '300px' }} variant="contained" color="primary" onClick={closeDialogForm}>Chiudi</Button>
                    </div>
                </>
            ) : (
                <div className={classes.root}>
                    <div className={classes.summary} style={{ border: '1px solid #cccc', borderRadius: '20px', padding: '20px' }}>
                        <Typography variant="h4" gutterBottom>{formData.deliveryOption}</Typography>
                        <Divider style={{ margin: '15px 0', backgroundColor: '#fff' }} />
                        <Typography variant="body1" className={classes.fontWeight}>Orario: </Typography>
                        <Typography variant="body1" gutterBottom className={classes.fontItalic}>{formData.deliveryTime} </Typography>
                        <Typography variant="body1" className={classes.fontWeight}>{formData.surname ? 'Nome e cognome:' : 'Nome:'}</Typography>
                        <Typography variant="body1" gutterBottom className={classes.fontItalic}>{formData.name} {formData.surname}</Typography>
                        <Typography variant="body1" className={classes.fontWeight}>Telefono: </Typography>
                        <Typography variant="body1" gutterBottom className={classes.fontItalic}>{formData.phoneNumber}</Typography>
                        {formData.address && (
                            <>
                                <Typography variant="body1" className={classes.fontWeight}>Indirizzo:</Typography>
                                <Typography variant="body1" gutterBottom className={classes.fontItalic}>{formData.address}</Typography>
                            </>
                        )}
                        {formData.city && (
                            <>
                                <Typography variant="body1" className={classes.fontWeight} >Comune:</Typography>
                                <Typography variant="body1" gutterBottom className={classes.fontItalic} >{formData.city}</Typography>
                            </>
                        )}

                        {formData.email && (
                            <>
                                <Typography variant="body1" className={classes.fontWeight} >Email:</Typography>
                                <Typography variant="body1" gutterBottom className={classes.fontItalic} >{formData.email}</Typography>
                            </>
                        )}

                        {formData.noteDelivery && (
                            <>
                                <Typography variant="body1" className={classes.fontWeight} >Note di consegna:</Typography>
                                <Typography variant="body1" gutterBottom className={classes.fontItalic} >{formData.noteDelivery}</Typography>
                            </>
                        )}
                    </div>

                    <div className={classes.orderItems}>
                        {cart.map((item, index) => (
                            <div key={index} className={classes.orderItem}>

                                <Grid container style={item.variants && item.variants.length ? { marginBottom: '10px' } : {}}>
                                    <Grid item xs={1}>
                                        <Badge overlap='rectangular' color='primary' badgeContent={`x${item.amount}`}></Badge>
                                    </Grid>
                                    <Grid item xs={8} >
                                        <Typography variant="body1" >{item.name}</Typography>
                                    </Grid>
                                    <Grid item xs={3} >
                                        <Typography variant="body1" >{(item.amount * getDishPrice(item)).toFixed(2).toString().replace('.', ',')} €</Typography>
                                    </Grid>
                                </Grid>

                                <Grid container style={item.note ? { marginBottom: '10px' } : {}}>
                                    <Grid item xs={12}>
                                        {item.variants && item.variants.length !== 0 && (<Typography variant="body1"><strong>Con:</strong> <em>{getDashVariants(item.variants)}</em></Typography>)}
                                    </Grid>
                                </Grid>
                                <Grid container>
                                    <Grid item xs={12}>
                                        {item.note && (<Typography variant="body1" ><strong>Note:</strong> <em>{item.note}</em></Typography>)}
                                    </Grid>
                                </Grid>

                                <Divider style={{ margin: '15px 0', backgroundColor: '#fff' }} />
                            </div>
                        ))}
                    </div>
                    <Typography style={{ letterSpacing: '1px' }} variant="subtitle1" gutterBottom>Subtotale: {totalPrice.toFixed(2).toString().replace('.', ',')} €</Typography>
                    {formData.deliveryOption.toLowerCase() === 'consegna' && <Typography style={{ letterSpacing: '1px' }} variant="subtitle1" gutterBottom>Spese di consegna: {Number(formData.costoConsegna).toFixed(2).toString().replace('.', ',')} €</Typography>}
                    <Typography style={{ letterSpacing: '1px' }} variant="h5" gutterBottom>Totale: {getSumTotal().toString().replace('.', ',')} €</Typography>

                    <div style={{ width: '100%', flexWrap: 'wrap', gap: '5px', marginTop: '30px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <Button
                            variant="contained"
                            color="secondary"
                            size='small'
                            onClick={() => goBack()}
                            startIcon={<ArrowBackIcon />}
                        >
                            Torna Indietro
                        </Button>
                        <Button
                            size='small'
                            variant="contained"
                            color="primary"
                            endIcon={<ArrowForwardIcon />}
                            onClick={handleOrder}
                            disabled={loading}
                        >
                            Invia ordine
                        </Button>
                    </div>
                </div>
            )}

        </>
    );
}

export default OrderSummary;
