import { TableCell, TableRow, TextField, ButtonGroup, Button } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import { withStyles } from "@mui/styles";
import Typography from "@mui/material/Typography";
import React, { useContext, useState } from "react";
import { DashboardContext } from "../../../contexts/dashboard.context";
import DeleteIcon from "@mui/icons-material/Delete";
import ReplayIcon from "@mui/icons-material/Replay";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";
import { NumberFormatEuro } from "../../customComponents/number.format.input";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';

const styles = (theme) => ({
  dialogContainer: {
    minWidth: 300,
  },
  dialogContent: { marginTop: theme.spacing(8) },
  deletedDish: {
    textDecoration: "line-through",
  },
  spacingLeft: {
    marginLeft: theme.spacing(2),
  },
  iconPadding: {
    padding: 4,
  },
});


const _NumberFormatEuro = React.forwardRef(function _NumberFormatEuro(props, ref) {
  return <NumberFormatEuro {...props} getInputRef={ref} />;
});

export default withStyles(styles)(function RoomDishRow({
  dish,
  classes,
  tableId,
  isEditing,
  handleChangeTotalPrice,
  setScontoTotalPrice,
  accettato
}) {
  const { setDish, getDish, modifyTable, data, getTable, setTable } =
    useContext(DashboardContext);
  const [amount, setAmount] = useState(dish.amount);


  const i = data.findIndex((res) => (res.tableId || res.deliveryId) === tableId);
  const table = i === -1 ? null : data[i];


  const handleChangeDish = (dishId, value) => {
    const [myDish] = getDish(tableId, dishId);
    setDish(tableId, dishId, { ...myDish, ...value });
  };

  const handleChangeAmount = (dishId, value) => {
    setAmount(value);
    const [myDish] = getDish(tableId, dishId);
    myDish.amount = value;
    setDish(tableId, dishId, { ...myDish });
    handleChangeTotalPrice(undefined, 'dishRow');

    setTimeout(() => {
      modifyTable(table.id, table);
    }, 3000);

  };


  const deleteItem = (tableId, dishId) => {
    let [myTable] = getTable(tableId);
    if (myTable) {
      const myDishIndex = myTable.reservations
        .map(({ id }) => id)
        .indexOf(dishId); //! dish.id
      if (myDishIndex !== -1) {
        let r = [...myTable.reservations];
        r[myDishIndex].status = "owner_deleted";
        setTable(tableId, { ...myTable, reservations: r });
        setScontoTotalPrice(0);
      }
    }

    setTimeout(() => {
      modifyTable(table.id, table);
    }, 1000);
  };

  const restoreItem = (tableId, dishId) => {
    let [myTable] = getTable(tableId);
    if (myTable) {
      const myDishIndex = myTable.reservations
        .map(({ id }) => id)
        .indexOf(dishId);
      if (myDishIndex !== -1) {
        let r = [...myTable.reservations];
        r[myDishIndex].status = "preparation";

        setTable(tableId, { ...myTable, reservations: r });
      }
    }

    setTimeout(() => {
      modifyTable(table.id, table);
    }, 1000);
  };

  return (
    <TableRow>
      <TableCell scope="row">
        <div className={dish.status === "owner_deleted" ? classes.deletedDish : ""}>
          {dish.status !== "owner_deleted" ? (
            <ButtonGroup disabled={accettato === 0} size="small" aria-label="small outlined button group">
              <Button
                aria-label="delete"
                variant="outlined"
                onClick={() => handleChangeAmount(dish.id, amount - 1)}
                className={classes.iconPadding}
                disabled={amount > 1 ? false : true}
              >
                <RemoveIcon fontSize="inherit" />
              </Button>
              <Button
                disableElevation
                disableFocusRipple
                disableRipple
              >
                {amount}
              </Button>
              <Button
                aria-label="delete"
                variant="outlined"
                onClick={() => handleChangeAmount(dish.id, amount + 1)}
                className={classes.iconPadding}
              //disabled={!dish.id}
              >
                <AddIcon fontSize="inherit" />
              </Button>
            </ButtonGroup>
          ) : (
            0
          )
          }
        </div>

      </TableCell>
      <TableCell>
        <Typography
          className={dish.status === "owner_deleted" ? classes.deletedDish : ""}
          style={{ display: 'flex', alignItems: 'center', gap: '16px' }}
        >
          {dish.printed === 1 ? <CheckCircleIcon color="primary" style={{ fontSize: '20px' }} /> : <AccessTimeFilledIcon color="warning" style={{ fontSize: '20px' }} />}  {dish.name}
        </Typography>
      </TableCell>
      <TableCell align="right">
        <div
          className={dish.status === "owner_deleted" ? classes.deletedDish : ""}
        >
          {isEditing && dish.status !== "owner_deleted" ? (
            <TextField
              label="Variante"
              fullWidth
              margin="normal"
              variant="outlined"
              className={classes.spacingBottom}
              name="variant"
              value={dish.variant || ''}
              onChange={(e) =>
                handleChangeDish(dish.id, {
                  variant: e.target.value,
                })
              }
            />
          ) : (
            dish.variant
          )}
        </div>
      </TableCell>
      <TableCell align="right">
        <Typography
          className={dish.status === "owner_deleted" ? classes.deletedDish : ""}
        >
          {dish.note}
        </Typography>
      </TableCell>
      <TableCell align="right">
        <div
          className={dish.status === "owner_deleted" ? classes.deletedDish : ""}
        >
          {isEditing && dish.status !== "owner_deleted" ? (
            <TextField
              label="Prezzo"
              fullWidth
              margin="normal"
              variant="outlined"
              className={classes.spacingBottom}
              name="price"
              InputProps={{
                inputComponent: _NumberFormatEuro,
              }}
              value={dish.price}
              onChange={(e) =>
                handleChangeDish(dish.id, {
                  price: Number(e.target.value),
                })
              }
            />
          ) : (
            <>{dish.price !== null ? `${(amount * dish.price).toFixed(2).toString().replace('.', ',')} €` : "€"}</>
          )}
        </div>
      </TableCell>
      <TableCell align="right">
        {
          (dish.status === "owner_deleted" ? (
            <IconButton onClick={() => restoreItem(tableId, dish.id)}>
              <ReplayIcon />
            </IconButton>
          ) : (
            <IconButton disabled={accettato === 0} onClick={() => deleteItem(tableId, dish.id)}>
              <DeleteIcon />
            </IconButton>
          ))}
      </TableCell>
    </TableRow>
  );
});
