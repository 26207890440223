import {
  Button,
  ButtonGroup,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import { makeStyles } from '@mui/styles';
import AddIcon from "@mui/icons-material/Add";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import RemoveIcon from "@mui/icons-material/Remove";
import React, { useContext, useState } from "react";
import { ReservationContext } from "../../../contexts/reservation.context";

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: 4,
    width: "100%",
  },
  media: {
    height: 140,
  },
  iconPadding: {
    padding: 4,
  },
  noPaddingBottom: { paddingBottom: 0 },
  checkbox: {
    margin: 0,
    padding: 0,
    float: "right",
  },
  spacingTop: {
    marginTop: theme.spacing(2) + ' !important',
  },
}));

const UserCartDish = ({ dish, dishIndex }) => {
  const classes = useStyles();
  const [amount, setAmount] = useState(dish.amount);
  const [note, setNote] = useState(dish.note || "");
  const [showNote, setShowNote] = useState(!!dish.note);
  const [showRemove, setShowRemove] = useState(true);
  const [error, setError] = useState("");
  const { getDishPrice, removeFromCart, setDishNote, changeAmount } =
    useContext(ReservationContext);

  const handleChangeAmount = (value) => {
    setAmount(value);
    changeAmount(dishIndex, value);
  };

  const handleClickRemove = () => {
    removeFromCart(dishIndex);
  };

  const handleClickAddNote = () => {
    setShowNote(true);
  };

  const handleBlurNote = () => {
    setShowRemove(true);
    if (note.length <= 150) {
      saveNote();
    }
  };

  const handleFocusNote = () => {
    setShowRemove(false);
  };

  const handleClickRemoveNote = () => {
    setShowNote(false);
    removeNote();
  };

  const handleNoteChange = (event) => {
    const newNote = event.target.value;
    if (newNote.length <= 150) {
      setNote(newNote);
      setError("");  // Clear the error if within limit
    } else {
      setError("Note cannot exceed 150 characters.");
    }
  };

  const saveNote = () => {
    if (note.length <= 150) {
      setDishNote(dishIndex, note);
    }
  };

  const removeNote = () => {
    setDishNote(dishIndex, null);
  };

  const getDashVariants = () => {
    const variantsArr = [];
    for (let x of dish.variants) {
      if (Array.isArray(x.selected)) {
        for (let y of x.selected) {
          variantsArr.push(y.label);
        }
      } else variantsArr.push(x.selected.label);
    }
    return variantsArr.join(" - ");
  };

  return (
    <>
      <Grid container>
        <Grid item xs={9} className={classes.spacingTop}>
          <Typography variant="subtitle1">{dish.name}</Typography>
        </Grid>
        <Grid item xs={3} className={classes.spacingTop}>
          {getDishPrice(dish) && getDishPrice(dish) !== 0 ? (
            <Typography align="right">{(amount * getDishPrice(dish)).toFixed(2).toString().replace('.', ',')} €</Typography>
          ) : (
            ""
          )}
        </Grid>

        {dish.variants && dish.variants.length !== 0 && (
          <Grid item xs={12}>
            <Typography color="textSecondary">{getDashVariants()}</Typography>
          </Grid>
        )}
        {showNote && (
          <>
            <Grid item xs={12} className={classes.spacingTop} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <TextField
                id="outlined-multiline-flexible"
                placeholder={"Note"}
                multiline
                maxRows={4}
                value={note}
                fullWidth
                onBlur={handleBlurNote}
                onFocus={handleFocusNote}
                onChange={handleNoteChange}
                error={!!error}
                helperText={error || `${note.length}/150`}
              />
              <IconButton onClick={showRemove ? handleClickRemoveNote : saveNote} style={{ marginTop: '-20px' }}>
                {showRemove ? <CloseIcon color="secondary" /> : <CheckIcon color="primary" />}
              </IconButton>
            </Grid>
          </>
        )}
        {!showNote && (
          <>
            <Grid item xs={12} className={classes.spacingTop}>
              <Button
                aria-label="delete"
                variant="outlined"
                color="primary"
                size="small"
                onClick={handleClickAddNote}
                className={classes.iconPadding}
              >
                Aggiungi nota
              </Button>
            </Grid>
          </>
        )}

        <Grid item xs={7} className={classes.spacingTop}>
          <ButtonGroup size="small" aria-label="small outlined button group">
            <Button
              aria-label="delete"
              variant="outlined"
              onClick={() => handleChangeAmount(amount - 1)}
              className={classes.iconPadding}
              disabled={amount < 2}
            >
              <RemoveIcon fontSize="inherit" />
            </Button>
            <Button disableElevation disableFocusRipple disableRipple>
              {amount}
            </Button>
            <Button
              aria-label="delete"
              variant="outlined"
              onClick={() => handleChangeAmount(amount + 1)}
              className={classes.iconPadding}
            >
              <AddIcon fontSize="inherit" />
            </Button>
          </ButtonGroup>
        </Grid>

        <Grid item xs={5} className={classes.spacingTop}>
          <Button
            aria-label="delete"
            variant="outlined"
            color="secondary"
            size="small"
            className={classes.iconPadding}
            fullWidth
            onClick={handleClickRemove}
          >
            Rimuovi
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export default UserCartDish;
